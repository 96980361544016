<app-header></app-header>
<div class="limiter">
  <div class="container-login100" style="background-image: url('assets/img/bg-01.jpg');">
    
    <div class="wrap-login100">
      <p>Deseo autorizar un visitante</p>
      <div class="login100-form validate-form">
        
        <ng-wizard [config]="config" (stepChanged)="stepChanged($event)">

          <ng-wizard-step [title]="'Paso 1'" [description]="'Validar Residente'" [canEnter]="isValidTypeBoolean"
            [canExit]="isValidFunctionReturnsBoolean.bind(this)">

            <div class="wrap-input100 mt-2">
              <select class="input100" style="border: 0px !important;" [(ngModel)]="idBuilding" id="building"
                (ngModelChange)="listarSectores()">
                <option value="">Seleccionar edificio</option>
                <option *ngFor="let edificio of listaEdificios" [value]="edificio.id">{{ edificio.name | uppercase }}
                </option>
              </select>
              <span class="focus-input100" data-placeholder="&#xf133;"></span>
            </div>

            <div class="wrap-input100 mt-2">
              <select class="input100" style="border: 0px !important;" [(ngModel)]="sector" id="sector"
                (ngModelChange)="listarCasas()">
                <option value="">Seleccionar sector</option>
                <option *ngFor="let sector of listaSectores" [value]="sector.name">{{ sector.name | uppercase }}
                </option>
              </select>
              <span class="focus-input100" data-placeholder="&#xf132;"></span>
            </div>

            <div class="wrap-input100 mt-2">
              <select class="input100" style="border: 0px !important;" [(ngModel)]="casa" id="home">
                <option value="">Seleccionar casa</option>
                <option *ngFor="let home of listaCasas" [value]="home.name">{{ home.name | uppercase }}</option>
              </select>
              <span class="focus-input100" data-placeholder="&#xf175;"></span>
            </div>

            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Celular" id="celular" autocomplete="off"
                [(ngModel)]="celular">
              <span class="focus-input100" data-placeholder="&#xf2be;"></span>
            </div>

            <div class="container-login100-form-btn">
              <button class="login100-form-btn" (click)="validateStep(1)">
                Validar Residente
              </button>
            </div>

          </ng-wizard-step>

          <ng-wizard-step [title]="'Paso 2'" [description]="'Confirmación de mensaje'">

            <span class="text-center txt1 mt-3">Se ha enviado el código de verificación al celular {{ celular }}</span>

            <div class="wrap-input100 mt-4">
              <input class="input100" type="text" placeholder="Ingresar código" id="otp" autocomplete="off"
                [(ngModel)]="otp">
              <span class="focus-input100" data-placeholder="&#xf262;"></span>
            </div>

            <div class="container-login100-form-btn">
              <button class="login100-form-btn" (click)="validateStep(2)">
                Confirmar
              </button>
            </div>
          </ng-wizard-step>

          <ng-wizard-step [title]="'Paso 3'" [description]="'Generar visita'">

            <div class="wrap-input100 mt-4">
              <input class="input100" type="text" placeholder="Nombre visitante" id="name" autocomplete="off"
                [(ngModel)]="name">
              <span class="focus-input100" data-placeholder="&#xf207;"></span>
            </div>

            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Celular visitante" id="phone" autocomplete="off"
                [(ngModel)]="phone">
              <span class="focus-input100" data-placeholder="&#xf2be;"></span>
            </div>

            <div class="wrap-input100">
              <input class="input100" type="email" placeholder="Email visitante" id="email" autocomplete="off"
                [(ngModel)]="email">
              <span class="focus-input100" data-placeholder="&#xf15a;"></span>
            </div>

            <div class="wrap-input100 mt-2">
              <select class="input100" style="border: 0px !important;" [(ngModel)]="step" id="step">
                <option value="0">Tiempo de espera</option>
                <option value="5">5 Min</option>
                <option value="15">15 Min</option>
                <option value="30">30 Min</option>
              </select>
              <span class="focus-input100" data-placeholder="&#xf333;"></span>
            </div>

            <div class="container-login100-form-btn">
              <button class="login100-form-btn" (click)="validateStep(3)">
                Generar visita
              </button>
            </div>
          </ng-wizard-step>
        </ng-wizard>

      </div>
    </div>
  </div>
</div>