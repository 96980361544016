import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME, TOOLBAR_POSITION } from 'ng-wizard';
import { ApiService } from '../services/api.service';
import { Sector } from '../model/sector';
import { Edificio } from '../model/edificio';
import { Casa } from '../model/casa';
import Swal from 'sweetalert2';
import { loadingFireToast } from 'src/assets/js/toast';

@Component({
  selector: 'app-resident-authorization',
  templateUrl: './resident-authorization.component.html',
  styleUrls: ['./resident-authorization.component.scss']
})
export class ResidentAuthorizationComponent implements OnInit {
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.dots,
    toolbarSettings: {
      toolbarPosition: TOOLBAR_POSITION.none
    },
    anchorSettings: {
      enableAnchorOnDoneStep: false
    }
  };
  validar: boolean = false;
  tokenTemp: string;
  idBuilding: string = '';
  listaEdificios: Array<Edificio> = [];
  sector: string = '';
  listaSectores: Array<Sector> = [];
  casa: string = '';
  listaCasas: Array<Casa> = [];
  celular: string;
  otp: string;
  name: string;
  email: string;
  phone: string;
  step: number = 0;

  constructor(private ngWizardService: NgWizardService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.generateToken();
  }

  generateToken() {
    this.apiService.post('generate-token', { info: 1 }).subscribe(
      (res) => {
        // console.log('res', res);
        this.tokenTemp = res.data.token;
        this.listarEdificios();
      },
      (error) => {
        console.log('error generando el token', error);
      }
    );
  }

  listarEdificios() {
    this.apiService.get(`residente/building-name/${this.tokenTemp}`).subscribe(
      (res) => {
        // console.log('res edificios', res);
        this.listaEdificios = res.data;
      },
      (error) => {
        console.log('error consultando edificios', error);
      }
    );
  }

  listarSectores() {
    this.apiService.get(`residente/sector-name/${this.idBuilding}/${this.tokenTemp}`).subscribe(
      (res) => {
        // console.log('res sector', res);
        this.listaSectores = res.data;
      },
      (error) => {
        console.log('error consultando los sectores', error);
      }
    );
  }

  listarCasas() {
    const sector = this.listaSectores.find(item => item.name === this.sector);
    this.apiService.get(`residente/home-name/${sector.id}/${this.tokenTemp}`).subscribe(
      (res) => {
        // console.log('res home', res);
        this.listaCasas = res.data;
      },
      (error) => {
        console.log('error consultando los home name', error);
      }
    );
  }

  validateStep(step) {
    // console.log('step', step);
    switch (step) {
      case 1:
        if (!this.validarResidente()) {
          return;
        }
        const loading = loadingFireToast('Validando residente, por favor espere...');
        this.apiService.post('residente/autorizar', {
          phone: this.celular,
          sector: this.sector,
          home: this.casa
        }).subscribe(
          (res) => {
            this.validar = false;
            loading.close();
            // console.log('res', res);
            Swal
              .fire({
                icon: 'success',
                title: 'Exito',
                text: 'los datos han sido validados correctamente.',
                timer: 2000,
              }).then(() => {
                this.showNextStep();
              });
          },
          (error) => {
            loading.close();
            Swal.fire('Confirmación', error.error.message, 'info');
            console.log('error validando residente', error);
          }
        );
        break;

      case 2:
        if (!this.validarOtp()) {
          return;
        }
        const loader = loadingFireToast('Confirmando mensaje, por favor espere...');
        this.apiService.post('residente/login', {
          phone: this.celular,
          otp: this.otp
        }).subscribe(
          (res) => {
            loader.close();
            this.validar = false;
            // console.log('res', res);
            Swal
              .fire({
                icon: 'success',
                title: 'Exito',
                text: 'El código ha sido confirmado correctamente.',
                timer: 2000,
              }).then(() => {
                this.showNextStep();
              });
          },
          (error) => {
            loader.close();
            Swal.fire('Confirmación', 'Código ha expirado o es inválido.', 'info');
            // console.log('error validando código', error);
          }
        );
        break;

      case 3:
        if (!this.validarVisita()) {
          return;
        }
        const load = loadingFireToast('Generando visita, por favor espere...');
        this.apiService.post('residente/crear-visita', {
          phoneResident: this.celular,
          name: this.name,
          email: this.email,
          phone: this.phone,
          step: this.step * 60
        }).subscribe(
          (res) => {
            load.close();
            // console.log('res', res);
            Swal
              .fire({
                icon: 'success',
                title: 'Exito',
                text: 'Visita generada correctamente.',
                timer: 5000,
              }).then(() => {
                location.reload();
              });
          },
          (error) => {
            load.close();
            Swal.fire('Error', 'Ocurrio un error generando la visita.', 'info');
            // console.log('error generando la visita', error);
          }
        );
        break;
    }
  }

  validarResidente() {
    if (!this.validar) {
      this.validar = true;
      this.validate(this.validar, this.idBuilding, 'El edificio es requerido.', 'building');
      this.validate(this.validar, this.sector, 'El sector es requerido.', 'sector');
      this.validate(this.validar, this.casa, 'La casa es requerida.', 'home');
      this.validate(this.validar, this.celular, 'El celular es requerido.', 'celular');
    }
    return this.validar;
  }

  validarOtp() {
    if (!this.validar) {
      this.validar = true;
      this.validate(this.validar, this.otp, 'El código es requerido.', 'otp');
    }
    return this.validar;
  }

  validarVisita() {
    if (!this.validar) {
      this.validar = true;
      this.validate(this.validar, this.name, 'El nombre del visitante es requerido.', 'name');
      if (this.email) {
        this.validate(this.validar, /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.email), 'El email del visitante no es válido.', 'email');
      }
      this.validate(this.validar, this.phone, 'El celular del visitante es requerido.', 'phone');
      this.validate(this.validar, this.step > 0, 'El tiempo del visitante es requerido.', 'step');
    }
    return this.validar;
  }

  validate(validate, condition, msg, id) {
    if (validate) {
      if (!condition) {
        this.validar = false;
        this.fireToast(msg);
        this.showField(id);
        return false;
      }
    }
  }

  fireToast(msg) {
    Swal.fire('Confirmación', msg, 'info');
  }

  showField(id) {
    const element = document.getElementById(id);
    const properties = element.getBoundingClientRect();
    window.scrollTo({
      top: properties.y,
      behavior: 'smooth'
    });
    document.getElementById(id).focus();
  }

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    console.log(args.step);
  }

  isValidTypeBoolean: boolean = true;

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }

}
