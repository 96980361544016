<app-header></app-header>
<header class="masthead text-white text-center">
  <div class="overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-xl-9 mx-auto">
        <h1 class="mb-5">VECIINO, es una solución para los conjuntos residenciales y empresariales</h1>
      </div>
      <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
        <form>
          <div class="form-row">
            <div class="col-12 col">
              <a class="btn btn-block btn-lg btn-success" href="https://app.veciino.co/register">Registrate aquí</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</header>
<section class="features-icons bg-light text-center">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
          <div class="features-icons-icon d-flex">
            <i class="icon-shield m-auto text-primary"></i>
          </div>
          <h3>Seguridad</h3>
          <p class="lead mb-0">Tendrá registro de quien ingresa y quien autoriza el ingreso, así como una evidencia en
            video del visitante.</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="features-icons-item mx-auto mb-0 mb-lg-3">
          <div class="features-icons-icon d-flex">
            <i class="icon-check m-auto text-primary"></i>
          </div>
          <h3>Fácil de usar</h3>
          <p class="lead mb-0">Mediante notificaciones en SMS el residente podrá saber quien lo visita y autorizarlo,
            los residentes podrán llenar un formulario sencillo y enviar un video con su motivo de visita.</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
          <div class="features-icons-icon d-flex">
            <i class="icon-layers m-auto text-primary"></i>
          </div>
          <h3>No requiere instalación</h3>
          <p class="lead mb-0">Sus residentes y visitantes no requieren instalar una aplicación.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="showcase">
  <div class="container-fluid p-0">
    <div class="row ">
      <div class="col-lg-6 order-lg-2 text-white showcase-img" style="background-image: url('assets/img/image1.png');">
      </div>
      <div class="col-lg-6 order-lg-1 my-auto showcase-text">
        <h2>Como funciona?</h2>
        <p class="lead mb-0">Cuando un visitante llega a nuestro conjunto, este deberá escanear el QR Code que se
          encuentra en la puerta principal, ayudando a no generar aglomeraciones en la recepción.</p>
        <br />
        <p class="lead mb-0">El visitante se registrará y grabará un video con su motivo de visita; el sistema enviará
          un mensaje al residente, el cual podrá ver y aceptará o no el ingreso.</p>
        <br />
        <p class="lead mb-0">Si es aceptado, recibirá un codigo de autorización y si no, recibirá un mensaje de la
          razón.</p>
      </div>
    </div>
    <div class="row ">
      <div class="col-lg-6 text-white showcase-img" style="background-image: url('assets/img/image2.png');"></div>
      <div class="col-lg-6 my-auto showcase-text">
        <h2>Cuales son los beneficios?</h2>
        <p class="lead mb-0">Evite aglomeraciones en la recepción de su conjunto</p>
        <br />
        <p class="lead mb-0">Agilice el ingreso</p>
        <br />
        <p class="lead mb-0">Deje atrás las minutas de la seguridad privada, con Veciino, puede tener esta información a
          la mano y de forma confiable.</p>
        <br />
        <p class="lead mb-0">Al tener un ingreso validado por el residente y en el cual tiene una evidencia en video del
          ingreso, ayuda a mejorar la seguridad de su comunidad.</p>
        <br />
        <a class="btn btn-block btn-lg btn-success" href="https://app.veciino.co/register">Registrate aquí</a>
      </div>
    </div>
  </div>
  <div class="container-fluid p-0">
    <div class="row ">
      <div class="col-lg-6 order-lg-2 text-white showcase-img" style="background-image: url('assets/img/login.png');">
      </div>
      <div class="col-lg-6 order-lg-1 my-auto showcase-text">
        <h2>Registrate</h2>
        <p class="lead mb-0">Para registrarte solo necesitas datos básicos del conjunto residencial el cual quieres gestionar.</p>
        <br />
      </div>
    </div>
    <div class="row ">
      <div class="col-lg-6 text-white showcase-img" style="background-image: url('assets/img/principal.jpg');"></div>
      <div class="col-lg-6 my-auto showcase-text">
        <h2>Dashboard</h2>
        <p class="lead mb-0">En nuestro Dashboard tienes acceso a crear nuevos residentes, ver reportes de visitas, ver los QR que se necesitan para la puerta principal y recepción.</p>
        <br />
        <a class="btn btn-block btn-lg btn-success" href="https://app.veciino.co/register">Registrate aquí</a>
      </div>
    </div>
  </div>
  <div class="container-fluid p-0">
    <div class="row ">
      <div class="col-lg-6 order-lg-2 text-white showcase-img" style="background-image: url('assets/img/reporte.jpg');">
      </div>
      <div class="col-lg-6 order-lg-1 my-auto showcase-text">
        <h2>Reportes</h2>
        <p class="lead mb-0">Gestione las visitas a su conjunto residencial.</p>
        <br />
      </div>
    </div>
    <div class="row ">
      <div class="col-lg-6 text-white showcase-img" style="background-image: url('assets/img/review.jpg');"></div>
      <div class="col-lg-6 my-auto showcase-text">
        <h2>Verifique visitas</h2>
        <p class="lead mb-0">Desea conocer quien o quienes estuvieron en su conjunto, puede hacerlo mediante el reporte y ver el video del visitante y como fue autorizado.</p>
        <br />
        <a class="btn btn-block btn-lg btn-success" href="https://app.veciino.co/register">Registrate aquí</a>
      </div>
    </div>
  </div>
</section>
<footer class="footer bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 h-100 text-center text-lg-left my-auto">
        <ul class="list-inline mb-2">
          <li class="list-inline-item">
            <a href="https://app.veciino.co/politica" target="_blank">Terminos y condiciones</a>
          </li>
        </ul>
        <p class="text-muted small mb-4 mb-lg-0">© Veciino 2021. All Rights Reserved.</p>
      </div>

    </div>
  </div>
</footer>