import Swal from 'sweetalert2';

export function fireToast(icon, title) {
	Swal.fire({
		icon,
		title
	})
}

export function loadingFireToast(title) {
	return Swal.fire({
		title,
		allowEscapeKey: false,
		allowOutsideClick: false,
		showConfirmButton: false,
		didOpen: () => {
			Swal.showLoading();
		}
	})
}