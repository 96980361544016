<div class="site-mobile-menu site-navbar-target">
  <div class="site-mobile-menu-header">
    <div class="site-mobile-menu-close mt-3">
      <span class="icon-close2 js-menu-toggle"></span>
    </div>
  </div>
  <div class="site-mobile-menu-body"></div>
</div>
<div class="top-bar">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <a href="#" class=""><span class="mr-2  icon-envelope-open-o"></span> <span
            class="d-none d-md-inline-block">contacto@veciino.co</span></a>
        <span class="mx-md-2 d-inline-block"></span>
        <a href="#" class=""><span class="mr-2  icon-phone"></span> <span class="d-none d-md-inline-block">57 + (320)
            849 4130</span></a>

        <div class="float-right">

          <!-- a href="https://twitter.com/?lang=es" target="_blank" class=""><span class="mr-2  icon-twitter"></span> <span
              class="d-none d-md-inline-block">Twitter</span></a>
          <span class="mx-md-2 d-inline-block"></span>
          <a href="https://www.facebook.com/" target="_blank" class=""><span class="mr-2  icon-facebook"></span> <span
              class="d-none d-md-inline-block">Facebook</span></a //-->

        </div>

      </div>

    </div>

  </div>
</div>

<div class="site-navbar js-sticky-header site-navbar-target" role="banner">

  <div class="container">
    <div class="row align-items-center position-relative">


      <div class="site-logo">
        <img src="assets/img/logo.png" width="100">
      </div>

      <div class="col-12">
        
        <nav class="site-navigation text-right ml-auto " role="navigation">

          <ul class="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
            <li><a href="/" class="nav-link">Inicio</a></li>
            <li class="has-children">
              <a class="nav-link">Soluciones</a>
              <ul class="dropdown arrow-top">
                <li><a href="https://app.veciino.co" class="nav-link">Conjunto residencial</a></li>
                <li><a href="/resident" class="nav-link">Residente</a></li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <div class="toggle-button d-inline-block d-lg-none"><a href="#"
          class="site-menu-toggle py-5 js-menu-toggle text-black"><span class="icon-menu h3"></span></a></div>
      
    </div>
  </div>

</div>